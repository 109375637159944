import React from "react";
import Layout from "../components/layout";

const PrestationsPage = () => (
  <Layout title="Prestations">
    <section className="container">
      <div className="row">
        <div className="col-4 center">
          <h2>Conseil</h2>
          <p>Accompagnement de clients sur différents sujets techniques et fonctionnels, sur tous types de projets Web et mobiles.</p>
        </div>
        <div className="col-4 center">
          <h2>Développement</h2>
          <p>Développement sur mesure de sites Web, thèmes ou plugins (Wordpress, Gatsby) ou applications mobiles hybrides (Flutter, ReactNative).</p>
        </div>
        <div className="col-4 center">
          <h2>Support</h2>
          <p>Suivi mensuel des mises à jour, réalisation de sauvegarde, analyse d'impacts, migration de bases de données.</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrestationsPage;
